<template>

  <v-dialog
    width="600"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>
          <span v-if="modeType=='create'">New Member</span>
          <span v-if="modeType=='edit'">Edit Member of</span>
        </span>
        <span v-if="modeType=='edit'"><span class="font-weight-black">Planning and Zoning Commission</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-row>
          <v-col>
            <v-text-field
              outlined
              hide-details
              name="email"
              v-model="emailAddress"
              label="Email"
              required
              :disabled="!showSearchButton"
              :rules="emailRules"
              :counter="50"
              @blur="searchMember"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              outlined
              hide-details
              label="First Name"
              v-model="memberInfo.firstName"
              :counter="35"
              :rules="nameRules"
              :disabled="readyToEnter===false"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              hide-details
              label="Last Name"
              v-model="memberInfo.lastName"
              :counter="35"
              :rules="nameRules"
              :disabled="readyToEnter===false"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Cancel</v-btn>
        <v-btn depressed width="130" v-if="modeType=='create' && showSearchButton===true" color="success" @click="searchMember">
          <v-progress-circular v-if="saving===true" indeterminate size="16"></v-progress-circular>
          Search
        </v-btn>
        <v-btn depressed width="130" v-if="showSaveButton===true" :disabled="!valid" color="secondary" @click="saveMember">
          <v-progress-circular v-if="saving===true" indeterminate size="16"></v-progress-circular>
          <span v-if="modeType=='create'">Add</span>
          <span v-if="modeType=='edit'">Update</span>
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-form>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'

function initialState () {
  return {
    valid: false,
    saving: false,
    readyToEnter: false,
    readyToSave: false,
    showSearchButton: true,
    showSaveButton: false,
    searchResult: 'none',
    memberInfo: {
      firstName: '',
      lastName: '',
      email: '',
      id: '',
      newUser: 0,
      boardId: ''
    },
    emailRules: [
      v => !!v || 'Email is required',
      v => (v && v.length <= 50) || 'Email must be less than 50 characters',
      v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid'
    ],
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 35) || 'Name must be less than 35 characters'
    ]
  }
}

export default Vue.extend({
  name: 'BoardMemberCreateUpdate',
  props: {
    boardId: Number,
    value: {
      required: true,
      type: Boolean
    },
    modeType: {
      required: true,
      type: String
    }
  },
  data: function () {
    return initialState()
  },
  methods: {
    saveMember () {
      this.saving = true
      axios.post(process.env.VUE_APP_BACKEND_URL + '/api/admin/board-members', this.memberInfo)
        .then((response) => {
          this.saving = false
          this.show = false
        })
    },
    searchMember () {
      if (this.saving === false) {
        this.saving = true
        const emailRegex = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (emailRegex.exec(this.memberInfo.email) === null) {
          this.saving = false
          return false
        }
        axios.post(process.env.VUE_APP_BACKEND_URL + '/api/admin/user-search', this.memberInfo)
          .then((response) => {
            this.saving = false
            this.showSaveButton = true
            this.memberInfo.boardId = this.boardId
            this.showSearchButton = false
            if (response.data.exists === 1) {
              this.readyToEnter = false
              this.readyToSave = true
              this.memberInfo.firstName = response.data.first_name
              this.memberInfo.lastName = response.data.last_name
              this.memberInfo.id = response.data.id
              this.memberInfo.newUser = 0
            } else {
              this.readyToEnter = true
              this.readyToSave = false
              this.memberInfo.firstName = ''
              this.memberInfo.lastName = ''
              this.memberInfo.newUser = 1
            }
          })
      }
    }
  },
  watch: {
    show: function (stateChange) {
      if (stateChange === false) {
        Object.assign(this.$data, initialState())
      }
    }
  },
  computed: {
    emailAddress: {
      get () {
        return this.memberInfo.email
      },
      set (value) {
        this.memberInfo.email = value
      }
    },
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
})
</script>
