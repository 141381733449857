<template>
  <portal>

    <v-row>
      <v-col cols="12">

        <v-breadcrumbs
          :items="breadcrumbsItems"
          class="pa-0 mb-8"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-row">

        <span class="d-flex align-center justify-center">
          <v-btn
            class="mr-3 secondary--text pa-0 pr-3"
            text
            @click="$router.push({ name: 'Boards' })"
          >
            <v-icon size="30">mdi-chevron-left</v-icon>
            Boards
          </v-btn>
          <h2 class="darkGrey--text">
            Members from {{ this.boardData.name }}
          </h2>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="darkGrey"
                size="18"
                class="ml-3"
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              Explanation for Board Members
            </span>
          </v-tooltip>
        </span>

        <v-spacer></v-spacer>

        <v-btn
          depressed
          height="40"
          color="orange lighten-2"
          class="white--text"
          @click="openModal('createUpdate', 'create')"
        >
          <v-icon size="18">mdi-plus</v-icon> Add Member
        </v-btn>

      </v-col>
    </v-row>

    <v-row class="my-6">
      <v-col>
        <v-divider class="custom-divider"></v-divider>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" offset="6">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-data-table
            :headers="headers"
            :items=this.boardData.members
            class="elevation-1"
            :footer-props="{
              'items-per-page-options': [10, 20, 30]
            }"
            :loading="loading"
            :search="search"
          >
            <template v-slot:item.name="{ item }">
              <span class="font-weight-bold d-flex py-4">
                {{ item.alias.user.first_name }} {{ item.alias.user.last_name }}

              </span>
            </template>
            <template v-slot:item.email="{ item }">
              {{ item.alias.meta_value }}
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex flex-row justify-center">

                  <v-tooltip
                    top
                    v-for="(itemMenu, index) in itemsMenu"
                    :key="index"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="darkGrey"
                        size="18"
                        v-bind="attrs"
                        v-on="on"
                        @click="openModal(itemMenu.actions, itemMenu.optional, item.id, item.alias.user.first_name, item.alias.user.last_name)"
                      >
                        <v-icon
                          size="20"
                          :color="itemMenu.type? itemMenu.type:'darkGrey'"
                        >
                          {{ itemMenu.icon }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ itemMenu.text }}
                    </span>
                  </v-tooltip>

              </div>
            </template>

          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <board-member-create-update v-model="modals.createUpdate" :modeType="modeType" :boardId="boardId"></board-member-create-update>
    <board-member-delete v-model="modals.delete" :boardName="boardData.name" :memberId="member.id" :memberFirstName="member.firstName" :memberLastName="member.lastName"></board-member-delete>

  </portal>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import Portal from '@/components/Portal'
import BoardMemberCreateUpdate from '@/components/Boards/BoardMemberCreateUpdate'
import BoardMemberDelete from '@/components/Boards/BoardMemberDelete'
import BoardMember from '@/models/admin/BoardMember'

export default Vue.extend({
  name: 'BoardMembers',
  components: {
    Portal,
    BoardMemberCreateUpdate,
    BoardMemberDelete
  },
  data: () => ({
    modeType: '',
    boardData: {
      name: ''
    },
    boardId: 0,
    member: {
      id: 0,
      firstName: '',
      lastName: ''
    },
    refreshPending: false,
    modals: {
      createUpdate: false,
      delete: false
    },
    breadcrumbsItems: [
      {
        text: 'Admin Panel',
        disabled: false,
        href: ''
      },
      {
        text: 'Boards',
        disabled: false,
        href: '/boards'
      },
      {
        text: 'Board Members',
        disabled: true,
        href: ''
      }
    ],
    itemsMenu: [
      { icon: 'mdi-delete', text: 'Delete', type: 'important', actions: 'delete' }
    ],
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Email', value: 'email', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false, width: '150px', align: 'center' }
    ],
    loading: false,
    search: ''
  }),
  beforeRouteUpdate (to, from, next) {
    // react to route changes...
    // don't forget to call next()
  },
  created () {
    this.getDataFromApi()
    this.boardId = parseInt(this.$route.params.id)
  },
  watch: {
    modals: {
      handler () {
        // This is temporary. TODO
        if (this.modals.createUpdate === true || this.modals.delete === true) {
          this.refreshPending = true
        } else if ((this.modals.createUpdate === false || this.modals.delete === false) && this.refreshPending === true) {
          this.getDataFromApi()
          this.refreshPending = false
        }
      },
      deep: true
    }
  },
  methods: {
    openModal (item, optional, id, firstName, lastName) {
      if (optional) this.modeType = optional
      this.modals[item] = true
      if (id) this.member.id = id
      if (firstName) this.member.firstName = firstName
      if (lastName) this.member.lastName = lastName
    },
    getDataFromApi () {
      this.loading = true
      axios.get(process.env.VUE_APP_BACKEND_URL + '/api/admin/board-members/' + this.$route.params.id)
        .then((response) => {
          this.boardData = response.data
          this.loading = false
        })
    }
  }
})
</script>
